import Header from "../components/header";
import React from "react";
import Footer from "../components/footer";
export default function TermsAndConditions() {
  return (
    <>
      <Header />
      <div className="container  mx-auto flex flex-col justify-between px-4 mt-4">
        <h1 className="text-2xl font-bold mb-4">Terms & Conditions</h1>
        <p className="mb-4">Last updated: 1 May, 2023</p>
        <p className="mb-4">
          Please read these Terms & Conditions ("Terms", "Terms & Conditions")
          carefully before using the Streamline Launcher application (the
          "Launcher") and the streamline.quest website (the "Website") operated
          by streamline.quest ("us", "we", or "our").
        </p>
        <p className="mb-4">
          Your access to and use of the Launcher and Website is conditioned upon
          your acceptance of and compliance with these Terms. These Terms apply
          to all visitors, users, and others who wish to access or use the
          Launcher and Website.
        </p>
        <p className="mb-4">
          By accessing or using the Launcher and Website, you agree to be bound
          by these Terms. If you disagree with any part of the Terms, then you
          do not have permission to access or use the Launcher and Website.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">Intellectual Property</h2>
        <p className="mb-4">
          The Launcher, Website, and their original content, features, and
          functionality are and will remain the exclusive property of
          streamline.quest and its licensors. The Launcher and Website are
          protected by copyright, trademark, and other laws of both [your
          country] and foreign countries. Our trademarks and trade dress may not
          be used in connection with any product or service without our prior
          written consent.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">User Conduct</h2>
        <p className="mb-4">
          You agree not to use the Launcher and Website in any way that:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Violates any applicable law or regulation;</li>
          <li>
            Infringes upon the rights of any third party, including copyright,
            trademark, privacy, publicity, or other personal or proprietary
            rights;
          </li>
          <li>Is fraudulent, false, misleading, or deceptive;</li>
          <li>Is defamatory, obscene, pornographic, vulgar, or offensive;</li>
          <li>
            Promotes discrimination, bigotry, racism, hatred, harassment, or
            harm against any individual or group;
          </li>
          <li>
            Is violent or threatening or promotes violence or actions that are
            threatening to any person or entity;
          </li>
          <li>Promotes illegal or harmful activities or substances.</li>
        </ul>
        <h2 className="text-xl font-bold mt-8 mb-4">Links To Other Websites</h2>
        <p className="mb-4">
          Our Launcher and Website may contain links to third-party websites or
          services that are not owned or controlled by Streamline Quest LLC. We have no
          control over and assume no responsibility for the content, privacy
          policies, or practices of any third-party websites or services. We do
          not warrant the offerings of any of these entities/individuals or
          their websites.
        </p>
        <p className="mb-4">
          You acknowledge and agree that Streamline Quest LLC shall not be responsible or
          liable, directly or indirectly, for any damage or loss caused or
          alleged to be caused by or in connection with the use of or reliance
          on any such content, goods, or services available on or through any
          such third-party websites or services.
        </p>
        <p className="mb-4">
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party websites or services that you visit.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">Termination</h2>
        <p className="mb-4">
          We may terminate or suspend your access to the Launcher and Website
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of the Terms.
        </p>
        <p className="mb-4">
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">Indemnification</h2>
        <p className="mb-4">
          You agree to defend, indemnify and hold harmless Streamline Quest LLC and its
          licensee and licensors, and their employees, contractors, agents,
          officers and directors, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney's fees), resulting from or
          arising out of a) your use and access of the Launcher and Website, or
          b) a breach of these Terms.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">Limitation Of Liability</h2>
        <p className="mb-4">
          In no event shall Streamline Quest LLC, nor its directors, employees, partners,
          agents, suppliers, or affiliates, be liable for any indirect,
          incidental, special, consequential or punitive damages, including
          without limitation, loss of profits, data, use, goodwill, or other
          intangible losses, resulting from (i) your access to or use of or
          inability to access or use the Launcher and Website; (ii) any conduct
          or content of any third party on the Launcher and Website; (iii) any
          content obtained from the Launcher and Website; and (iv) unauthorized
          access, use or alteration of your transmissions or content, whether
          based on warranty, contract, tort (including negligence) or any other
          legal theory, whether or not we have been informed of the possibility
          of such damage, and even if a remedy set forth herein is found to have
          failed of its essential purpose.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">Disclaimer</h2>
        <p className="mb-4">
          Your use of the Launcher and Website is at your sole risk. The
          Launcher and Website are provided on an "AS IS" and "AS AVAILABLE"
          basis. The Launcher and Website are provided without warranties of any
          kind, whether express or implied, including, but not limited to,
          implied warranties of merchantability, fitness for a particular
          purpose, non-infringement, or course of performance.
        </p>
        <p className="mb-4">
          Streamline Quest LLC, its subsidiaries, affiliates, and its licensors do not
          warrant that a) the Launcher and Website will function uninterrupted,
          secure or available at any particular time or location; b) any errors
          or defects will be corrected; c)the Launcher and Website are free of
          viruses or other harmful components; or d) the results of using the
          Launcher and Website will meet your requirements.
        </p>{" "}
        <h2 className="text-xl font-bold mt-8 mb-4">Governing Law</h2>
        <p className="mb-4">
          These Terms shall be governed and construed in accordance with the
          laws of [your country], without regard to its conflict of law
          provisions.
        </p>
        <p className="mb-4">
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Launcher and
          Website and supersede and replace any prior agreements we might have
          had between us regarding the Launcher and Website.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">Changes</h2>
        <p className="mb-4">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material, we will provide at
          least 30 days' notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p className="mb-4">
          By continuing to access or use our Launcher and Website after those
          revisions become effective, you agree to be bound by the revised
          terms. If you do not agree to the new terms, in whole or in part,
          please stop using the Launcher and Website.
        </p>
        <h2 className="text-xl font-bold mt-8 mb-4">Contact Us</h2>
        <p className="mb-2">
          If you have any questions about these Terms, please contact us:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Email: support@streamline.quest</li>
        </ul>
      </div>
      <Footer />
    </>
  );
}
