import screenshot1 from "../assets/Screenshot1.png";
import screenshot2 from "../assets/Screenshot2.png";
import screenshot3 from "../assets/Screenshot3.png";
import React from "react";
import { useEffect, useState } from "react";

import "./home.css";
import Header from "../components/header";
import Footer from "../components/footer";

function Home() {
  const images = [screenshot1, screenshot2, screenshot3];
  const [currentImage, setCurrentImage] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex items-center justify-center grow">
        <div className="container mx-auto flex flex-col sm:flex-row p-4 items-center">
          <div className="w-1/2">
            <h2 className="text-4xl font-bold mb-4 pl-3.5">
              Streamline Launcher
            </h2>
            <p className="text-gray-700 pl-3.5 pb-1">
              Experience a seamless and intuitive way to access your favorite
              apps with Streamline Launcher. Our Android launcher simplifies
              your home screen and app management, providing you with a
              hassle-free mobile experience.
            </p>
            <a href="https://play.google.com/store/apps/details?id=quest.streamline.launcher&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                className="w-48"
              />
            </a>
          </div>
          <div className="w-1/2 ">
            <div className="slider overflow-hidden">
              {images.map((image, index) => (
                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                <img
                  key={index}
                  src={image}
                  alt={`Slider Image ${index + 1}`}
                  className={`slider-image ${
                    index === currentImage ? "active" : ""
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer showGooglePlayTerms />
    </div>
  );
}

export default Home;
