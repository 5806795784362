// import logo from "../assets/logo.png"  ;
import React from "react";

export default function Header() {
  return (
    <header className="py-4">
      <div className="container mx-auto flex items-center justify-between px-4">
        <div className="flex items-center">
          <a href="/">
            {/* <img src={logo} alt="Logo" className="w-8 h-8" /> */}
          </a>
        </div>
        <nav>
          <a href="/" className="ml-4 hidden sm:block">
            Home
          </a>
        </nav>
      </div>
    </header>
  );
}
