import React from "react";
export default function Footer({
  showGooglePlayTerms,
}: {
  showGooglePlayTerms?: boolean;
}) {
  return (
    <footer className="bg-gray-800 py-4 mt-auto">
      <div className="container mx-auto flex flex-col items-center sm:flex-row justify-center sm:justify-between px-4">
        <div className="text-white text-sm">
          &copy; 2023 streamline.quest. All Rights Reserved.{" "}
          {showGooglePlayTerms &&
            `Google Play and the Google Play logo are trademarks of Google LLC.`}
        </div>
        <div className="flex flex-col sm:flex-row space-x-4 justify-center  items-center">
          <a href="/privacy-policy" className="text-white text-sm">
            Privacy Policy
          </a>
          <a href="/terms-and-conditions" className="text-white text-sm">
            Terms & Conditions
          </a>
        </div>
      </div>
    </footer>
  );
}
